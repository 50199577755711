import ContentDetail from 'views/Live/OnAirSchedule/primitives/ContentDetail';
import ContentDetailLink from 'views/Live/OnAirSchedule/primitives/ContentDetailLink';
import countryCodes from 'constants/countryCodes';
import Info from 'views/Live/OnAirSchedule/primitives/Info';
import InfoWrapper from 'views/Live/OnAirSchedule/primitives/InfoWrapper';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import ShareButton from 'components/Social/Share';
import Title from 'views/Live/OnAirSchedule/primitives/Title';
import Truncate from 'components/Truncate';
import useFeature from 'hooks/useFeature';
import { ConnectedModals } from 'state/UI/constants';
import { getAllAccessPreview } from 'state/Entitlements/selectors';
import { getCountryCode } from 'state/Config/selectors';
import { getIsAnonymous } from 'state/Session/selectors';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Image, PlayButtonWrapper } from './primitives';
import { Data as ItemSelectedData } from 'modules/Analytics/helpers/itemSelected';
import { Menu } from 'components/Tooltip';
import { openModal, openSignupModal } from 'state/UI/actions';
import { SaveDeleteComponent } from 'modules/Analytics/helpers/saveDelete';
import { ShareTypes } from 'components/Social/Share/constants';
import { STATION_TYPE } from 'constants/stationTypes';
import { THUMB_RES } from 'components/MediaServerImage';
import { useDispatch, useSelector } from 'react-redux';
import type { PlayedFrom } from 'modules/Analytics/types';
import type { Track } from 'state/Tracks/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  itemSelectedLocation: string;
  playedFrom: PlayedFrom;
  track: Track;
  translate: IGetTranslateFunctionResponse;
};

function SongRow({
  itemSelectedLocation,
  playedFrom,
  track,
  translate,
}: Props) {
  const internationalPlaylistRadioEnabled = useFeature(
    'internationalPlaylistRadio',
  );
  const allAccessPreview = useSelector(getAllAccessPreview);
  const isAnonymous = useSelector(getIsAnonymous);
  const countryCode = useSelector(getCountryCode);
  const dispatch = useDispatch();
  const enableAddToPlaylist =
    countryCode === countryCodes.US || internationalPlaylistRadioEnabled;

  const playButton = (
    <PlayButton
      artistId={track.artistId}
      artistName={track.artistName}
      currentAlbumId={track.catalogId}
      currentAlbumTitle={track.title}
      deferPlay={!!track.url}
      name={track.catalogType === 'track' ? undefined : track.title}
      playedFrom={playedFrom}
      playlistId={String(track.catalogId)}
      stationId={track.catalogId}
      stationType={track.catalogType}
      trackId={track.id}
      trackName={track.title}
    />
  );

  const itemSelected = {
    id: track.id,
    location: itemSelectedLocation,
    name: track.artistName,
    trackId: track.id,
    trackTitle: track.trackTitle,
    trackArtistId: track.artistId,
    type: track.catalogType,
    trackArtistName: track.artistName,
    trackName: track.trackTitle,
  } as ItemSelectedData;

  const onAddToPlaylist = () => {
    if (allAccessPreview && isAnonymous) {
      dispatch(openSignupModal({ context: 'all_access_Preview' }));
    } else if (isAnonymous) {
      dispatch(openSignupModal({ context: 'add_to_playlist' }));
    } else if (enableAddToPlaylist) {
      dispatch(
        openModal({
          id: ConnectedModals.AddToPlaylist,
          context: {
            trackIds: [track.id],
            component: SaveDeleteComponent.ListSongsOverflow,
            type: STATION_TYPE.ARTIST,
          },
        }),
      );
    }
  };

  return (
    <ContentDetail hasLink={!!track.url}>
      <NavLink
        css={{ float: 'left', marginRight: '1rem' }}
        dataTest="article-link"
        itemSelected={itemSelected}
        title={track.title}
        to={track.url}
      >
        <Image
          alt="Track Artwork"
          aspectRatio={1}
          height={THUMB_RES}
          id={track.id}
          type="track"
          width={THUMB_RES}
        >
          <PlayButtonWrapper>{playButton}</PlayButtonWrapper>
        </Image>
      </NavLink>
      <InfoWrapper>
        <Title>
          <NavLink
            dataTest="song-name"
            itemSelected={itemSelected}
            to={track.urls.song}
          >
            <Truncate lines={2}>{track.title}</Truncate>
          </NavLink>
        </Title>
        <Info>
          <NavLink
            dataTest="song-artist"
            itemSelected={itemSelected}
            to={track.urls.artist}
          >
            <Truncate lines={1}>{track.description}</Truncate>
          </NavLink>
        </Info>
      </InfoWrapper>
      <ContentDetailLink>
        <Menu>
          <Menu.List>
            <Menu.Item>
              <NavLink
                dataTest="share-song"
                key={`save|${track.id}`}
                title={translate('Share Song')}
              >
                <ShareButton
                  episodeId={track.id}
                  key={`share|${track.id}`}
                  seedId={track.id}
                  seedType={STATION_TYPE.TRACK}
                  type={ShareTypes.Link}
                  url={track.url}
                />
              </NavLink>
            </Menu.Item>
            {enableAddToPlaylist && (
              <Menu.Item>
                <NavLink
                  dataTest="add-to-playlist"
                  key="addToPlaylist"
                  onClick={onAddToPlaylist}
                  title={translate('Add to Playlist')}
                >
                  {translate('Add to Playlist')}
                </NavLink>
              </Menu.Item>
            )}
          </Menu.List>
        </Menu>
      </ContentDetailLink>
    </ContentDetail>
  );
}

export default SongRow;
