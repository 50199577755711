import RelatedPodcasts from './RelatedPodcasts';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRelatedPodcasts } from 'state/Live/selectors';
import { Podcast } from 'state/Podcast/types';
import { requestRelatedPodcasts } from 'state/Podcast/actions';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<State, { relatedPodcasts: Array<Podcast> }>({
    relatedPodcasts: getRelatedPodcasts,
  }),
  { requestRelated: requestRelatedPodcasts },
)(RelatedPodcasts);
