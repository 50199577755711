import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = { hasLink?: boolean };

const ContentDetail = styled('div')<Props>(({ hasLink = true, theme }) => ({
  background: theme.colors.white.primary,
  borderRadius: '.6rem',
  display: 'flex',
  padding: '1rem',
  pointerEvents: hasLink ? 'auto' : 'none',
  position: 'relative',
  width: '31.5%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    ':last-child': {
      display: 'none',
    },
    width: '48%',
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['420'])]: {
    ':last-child': {
      display: 'block',
    },
    marginBottom: '1rem',
    width: '100%',
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['320'])]: {
    ':nth-child(2)': {
      display: 'none',
    },
    width: '100%',
  },
}));

export default ContentDetail;
