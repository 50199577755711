import Head from './LiveStationHead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getBand,
  getCallLetters,
  getCity,
  getDescription,
  getFreq,
  getGenre,
  getId,
  getLiveStationPath,
  getMarket,
  getStateAbbreviation,
  getStationLogo,
  getStationName,
  getWebsite,
} from 'state/Live/selectors';
import { getSiteUrl } from 'state/Config/selectors';

export default connect(
  createStructuredSelector({
    band: getBand,
    callLetters: getCallLetters,
    city: getCity,
    description: getDescription,
    freq: getFreq,
    genre: getGenre,
    id: getId,
    logo: getStationLogo,
    market: getMarket,
    pagePath: getLiveStationPath,
    siteUrl: getSiteUrl,
    stateAbbreviation: getStateAbbreviation,
    stationName: getStationName,
    website: getWebsite,
  }),
)(Head);
