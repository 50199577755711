import CatalogImage from 'components/MediaServerImage/CatalogImage';
import styled from '@emotion/styled';

const Image = styled(CatalogImage)({
  borderRadius: '6px',
  overflow: 'hidden',
  width: '5rem',
});

export default Image;
