import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [mediaQueryBuilder(theme.mediaQueries.max.width['420'])]: {
    flexFlow: 'column',
  },
}));

export default Wrapper;
