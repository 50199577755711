import styled from '@emotion/styled';

const LiveBox = styled('div')(({ theme }) => ({
  background: theme.colors.red.primary,
  borderRadius: '.3rem',
  color: theme.colors.white.primary,
  float: 'left',
  fontSize: theme.fonts.size[12],
  marginTop: '.5rem',
  padding: '0 .6rem',
  textTransform: 'uppercase',
}));

export default LiveBox;
