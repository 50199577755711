import styled from '@emotion/styled';

const Info = styled('div')(({ theme }) => ({
  float: 'left',
  fontSize: theme.fonts.size[12],
  marginTop: '.3rem',
  width: '100%',
}));

export default Info;
