import H1 from 'primitives/Typography/Headings/H1';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const HeroTitle = styled(H1)(({ theme }) => ({
  color: theme.colors.white.primary,
  fontSize: theme.fonts.size['32'],
  lineHeight: theme.fonts.lineHeight['36'],
  marginTop: '2rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['980'])]: {
    fontSize: theme.fonts.size['24'],
    lineHeight: theme.fonts.lineHeight['30'],
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    fontSize: theme.fonts.size['20'],
    lineHeight: theme.fonts.lineHeight['24'],
    margin: '.5rem 0',
  },
}));

export default HeroTitle;
