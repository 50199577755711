import CatalogImage from 'components/MediaServerImage/CatalogImage';
import NavLink from 'components/NavLink';
import TileThumb from './primitives/TileThumb';
import TileTitle from './primitives/TileTitle';
import Truncate from 'components/Truncate';
import WideTileContainer from './primitives/WideTileContainer';
import { ASPECT_RATIOS } from 'utils/mediaServerImageMaker/opsString/constants';
import { fit, resize } from 'utils/mediaServerImageMaker/opsString';
import { WideTileProps } from './types';

function WideTile({
  clickedFrom,
  eyebrow,
  height = 171,
  imgUrl,
  itemSelected,
  target,
  title,
  url,
}: WideTileProps) {
  return (
    <WideTileContainer>
      <NavLink
        clickedFrom={clickedFrom}
        itemSelected={itemSelected}
        target={target}
        to={url}
      >
        <TileThumb>
          <CatalogImage
            alt={title}
            aspectRatio={16 / 9}
            ops={[fit(...ASPECT_RATIOS['16:9']), resize(0, height)]}
            src={imgUrl}
          />
        </TileThumb>
      </NavLink>
      <TileTitle>
        {eyebrow}
        <NavLink
          clickedFrom={clickedFrom}
          itemSelected={itemSelected}
          target={target}
          to={url}
        >
          <Truncate lines={2}>{title}</Truncate>
        </NavLink>
      </TileTitle>
    </WideTileContainer>
  );
}

export default WideTile;
