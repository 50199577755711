import ConnectDropdown from './primitives/ConnectDropdown';
import ConnectDropdownMenuItem from './primitives/ConnectDropdownMenuItem';
import ConnectDropdownNameWrapper from './primitives/ConnectDropdownNameWrapper';
import ExpandDown from 'styles/icons/ExpandDown';
import FacebookCircle from 'styles/icons/FacebookCircle';
import Instagram from 'styles/icons/Instagram';
import Link from 'components/NavLink';
import MoreIcon from 'styles/icons/More';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import SocialLabel from 'components/Social/primitives/SocialLabel';
import theme from 'styles/themes/default';
import TikTok from 'styles/icons/TikTok';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import X from 'styles/icons/X';
import { getSocial } from 'state/Live/selectors';
import { Menu } from 'components/Tooltip';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import type { StationContact } from 'state/Live/selectors';

const iconMapper: { [key: string]: ReactNode } = {
  twitter: <X height="16" width="3rem" />,
  instagram: <Instagram width="3rem" />,
  facebook: (
    <FacebookCircle css={{ marginBottom: '-.2rem' }} height="20" width="3rem" />
  ),
  tiktok: <TikTok width="3rem" />,
};

function Dropdown() {
  const translate = useTranslate();
  const social: StationContact = useSelector(getSocial);

  const target = (
    <OutlinedButton
      data-test="social-connect"
      marginLeft="1rem"
      size="small"
      styleType="light"
    >
      <ExpandDown color={theme.colors.white.primary} height="12" width="12" />
      <SocialLabel data-test="social-connect-label" inHero>
        {translate('Connect')}
      </SocialLabel>
    </OutlinedButton>
  );
  return (
    <ConnectDropdown>
      <Menu target={target}>
        <Menu.List>
          {!!social.networks &&
            social.networks.map(
              (item: { key: string; name: string; link: string }) => (
                <ConnectDropdownMenuItem key={`connect|${item.name}`}>
                  <Link
                    clickedFrom={`live_profile|connect|${item.key}`}
                    css={{ display: 'flex', alignItems: 'center' }}
                    to={item.link}
                  >
                    {iconMapper[item.key]}
                    <ConnectDropdownNameWrapper>
                      {item.name}
                    </ConnectDropdownNameWrapper>
                    <MoreIcon width="3rem" />
                  </Link>
                </ConnectDropdownMenuItem>
              ),
            )}
          {!!social.request_phone_number && (
            <ConnectDropdownMenuItem key="connect|phone">
              <Link
                clickedFrom="live_profile|connect|call"
                css={{ display: 'block', width: '100%' }}
                onClick={() => {
                  window.location.href = `tel:${String(
                    social.request_phone_number,
                  )}`;
                }}
                to={`tel:${String(social.request_phone_number)}`}
              >
                {translate('Call ')}
                {social.request_phone_number}
              </Link>
            </ConnectDropdownMenuItem>
          )}
          {!!social.sms_number && (
            <ConnectDropdownMenuItem key="connect|sms">
              <Link
                clickedFrom="live_profile|connect|text"
                css={{ display: 'block', width: '100%' }}
                onClick={() => {
                  window.location.href = `sms:${String(social.sms_number)}`;
                }}
                to={`sms:${String(social.sms_number)}`}
              >
                {translate('Text ')}
                {social.sms_number}
              </Link>
            </ConnectDropdownMenuItem>
          )}
        </Menu.List>
      </Menu>
    </ConnectDropdown>
  );
}

export default Dropdown;
