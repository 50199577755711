import NewConnectDropdown from 'components/Dropdown/NewConnectDropdown/Dropdown';
import ShouldShow from 'components/ShouldShow';
import type { StationContact } from 'state/Live/selectors';

type Props = {
  stationContact: StationContact;
  supportsConnect?: boolean;
};

function SocialConnect({ stationContact, supportsConnect }: Props) {
  return (
    <ShouldShow
      shouldShow={
        supportsConnect &&
        Object.keys(stationContact)
          .map(key => stationContact[key as keyof StationContact])
          .some(val => val)
      }
    >
      <NewConnectDropdown />
    </ShouldShow>
  );
}
export default SocialConnect;
