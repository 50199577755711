import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ShareButtonWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  float: 'right',
  marginLeft: '1rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
  },
}));

export default ShareButtonWrapper;
