import styled from '@emotion/styled';

const TileTitle = styled('div')(({ theme }) => ({
  color: theme.colors.black.secondary,
  fontSize: theme.fonts.size[15],
  marginTop: '0.9rem',
  padding: '0 0.5rem 0.5rem',
  a: {
    color: theme.colors.black.secondary,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default TileTitle;
