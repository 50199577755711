import styled from '@emotion/styled';

const PlayButtonWrapper = styled('div')(({ theme }) => ({
  '& > button': {
    borderColor: theme.colors.white.primary,
    height: '50%',
    opacity: 0,
    position: 'relative',
    svg: { fill: theme.colors.white.primary },
    transition: 'opacity 200ms ease, visibility 200ms ease',
    visibility: 'hidden',
    width: '50%',
    zIndex: 1,
  },

  '&::after': {
    background: theme.colors.transparent.primary,
    content: `''`,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    transition: 'background-color 200ms ease',
    width: '100%',
  },

  '&:hover': {
    '& > button': {
      opacity: 1,
      visibility: 'visible',
    },

    '&::after': {
      background: theme.colors.transparent.dark,
    },
  },

  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
}));

export default PlayButtonWrapper;
