import LiveHero from './LiveHero';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getDescription,
  getIsFavorite,
  getSeedId,
  getSeedType,
  getSocial,
  getStationLogo,
  getStationName,
} from 'state/Live/selectors';
import { getMediaServerUrl } from 'state/Config/selectors';
import { getSubscriptionType } from 'state/Entitlements/selectors';
import { noLogo } from 'state/Hero/selectors';
import { State } from 'state/types';
import { toggleFavoriteStation } from 'state/Live/actions';
import type { ConnectedProps } from 'views/Live/LiveHero/types';

export default connect(
  createStructuredSelector<State, ConnectedProps>({
    description: getDescription,
    isFavorite: getIsFavorite,
    logo: getStationLogo,
    mediaServerUrl: getMediaServerUrl,
    noLogo,
    seedId: getSeedId,
    seedType: getSeedType,
    stationContact: getSocial,
    stationName: getStationName,
    subscriptionType: getSubscriptionType,
  }),
  { toggleFavoriteStation },
)(LiveHero);
