import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  count?: number;
};

const WideTilesList = styled('div')<Props>(({ theme, count = 0 }) => {
  // for two or fewer tiles, justify to start instead of space-between (IHRWEB-17098)
  const spacing = count <= 2 ? 'start' : 'space-between';
  return {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: spacing,
    margin: '0 -0.5rem',
    width: 'calc(100% + 1rem)',
    [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
      flexFlow: 'column',
    },
  };
});

export default WideTilesList;
