import RelatedPlaylists from './RelatedPlaylists';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRelatedPlaylists } from 'state/Live/selectors';
import { requestRelatedPlaylists } from 'state/Playlist/actions';
import { State } from 'state/types';
import type { Playlist } from 'state/Playlist/types';

export default connect(
  createStructuredSelector<State, { relatedPlaylists: Array<Playlist> }>({
    relatedPlaylists: getRelatedPlaylists,
  }),
  { requestRelated: requestRelatedPlaylists },
)(RelatedPlaylists);
