import styled from '@emotion/styled';
import TargetButton from 'components/Tooltip/primitives/TargetButton';

const ConnectDropdown = styled('div')({
  [TargetButton.toString()]: {
    padding: '0',
  },
});

export default ConnectDropdown;
