import checkAutoplay from 'web-player/autoplay';
import factory from 'state/factory';
import LatestNews from '../LatestNews';
import ListenInApp from 'components/ListenInApp';
import LiveHero from 'views/Live/LiveHero';
import LivePromotionSection from 'views/Live/LivePromotionSection';
import LiveSimilarSection from 'views/Live/LiveSimilarSection';
import LiveStationHead from 'views/Live/LiveStationHead';
import OnAirSchedule from 'views/Live/OnAirSchedule';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import RecentlyPlayed from 'views/Live/RecentlyPlayed';
import RelatedPlaylists from '../RelatedPlaylists';
import RelatedPodcasts from '../RelatedPodcasts';
import withAnalytics from 'modules/Analytics/withAnalytics';
import { Genre } from 'state/Genres/types';
import { getUserType } from 'state/User/selectors';
import { LiveStation } from 'state/Live/types';
import { openSignupModal } from 'state/UI/actions';
import { requestRelatedPlaylists } from 'state/Playlist/actions';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';
import type { SocialProps } from 'components/Social/types';

type Props = {
  ads?: {
    provider_id?: string;
    enable_triton_token?: string;
    audio_ad_provider?: string;
  };
  callLetters?: string;
  currentStation: LiveStation;
  hasLocalSocial: boolean;
  pagePath: string;
  seedId: number;
  stationLoaded: boolean;
  genres?: Array<Genre>;
  queryParam: {
    showRegGate: boolean;
  };
};

const LIVE_PROFILE_LIMIT = 5;

const store = factory();

const openSignup = (context: string) => openSignupModal({ context });

function LiveProfile(props: Props) {
  const dispatch = useDispatch();
  const userType = useSelector(getUserType);
  const [noAds, setNoAds] = useState(false);
  const {
    currentStation,
    hasLocalSocial,
    pagePath,
    seedId,
    stationLoaded,
    queryParam,
  } = props;
  const { seedType = 'live', stationName = '' } = currentStation;

  if (queryParam.showRegGate) {
    store.dispatch(openSignup(queryParam.showRegGate?.toString()));
  }

  const social: SocialProps = {
    dimensions: WIDGET_DIMENSIONS.LIVE,
    seedId,
    seedType,
    stationName,
    supportsConnect: hasLocalSocial,
    supportsShare: true,
    url: `/live/${slugify(stationName)}-${seedId}/`,
  };

  // IHRWEB-16968: force pagebody rerender after hydration so that AA users get full page view
  useLayoutEffect(() => {
    setNoAds(userType === SUBSCRIPTION_TYPE.PREMIUM);
  }, []);

  useEffect(() => {
    if (!stationLoaded) return;
    checkAutoplay({ seedId, seedType: STATION_TYPE.LIVE });
  }, [stationLoaded, seedId]);

  useEffect(() => {
    dispatch(requestRelatedPlaylists(currentStation.relatedPlaylistIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStation.id, dispatch]);

  return (
    <>
      <LiveStationHead seedId={seedId} seedType={seedType} />
      {seedId && (
        <>
          <LiveHero social={social} />
          <ListenInApp seedId={seedId} seedType={seedType} />
          <PageBody dataTest={ViewName.LiveProfile} noAds={noAds}>
            <OnAirSchedule clickedFrom="live_profile|on_air|tile" />
            <RecentlyPlayed
              itemSelectedLocation="live_profile|recently_played|tile"
              key="recentlyPlayed"
              playedFrom={PLAYED_FROM.PROF_LIVE_RECENT}
              seedId={seedId}
            />
            <LivePromotionSection />
            <RelatedPodcasts
              stationName={stationName}
              tilesInRow={LIVE_PROFILE_LIMIT}
            />
            <RelatedPlaylists
              stationName={stationName}
              tilesInRow={LIVE_PROFILE_LIMIT}
            />
            <LiveSimilarSection
              hasBottomMargin={false}
              isAbTest
              itemSelectedLocation="live_profile|similar_stations|tile"
              playedFrom={PLAYED_FROM.PROF_LIVE_MAIN_SIMILAR}
              singleRow
              stationId={seedId}
              stationName={stationName}
              stationType={seedType}
              tilesInRow={LIVE_PROFILE_LIMIT}
              url={`${pagePath}similar/`}
            />
            <LatestNews clickedFrom="live_profile|the_latest|tile" />
          </PageBody>
        </>
      )}
    </>
  );
}

export default withAnalytics(
  ({ callLetters, currentStation, seedId, genres, ads }: Props) => ({
    id: `live|${seedId}`,
    name: callLetters,
    stationCallLetter: callLetters,
    stationFormat: currentStation?.format,
    stationMarket: currentStation?.markets,
    pageName: 'live_profile',
    stationName: currentStation?.stationName,
    genres,
    providerId: ads?.provider_id,
    enableTritonToken: ads?.enable_triton_token,
  }),
  {
    trackOnDidUpdate: (prevProps: Props, nextProps: Props) =>
      !!nextProps.seedId &&
      String(nextProps.seedId) !== String(prevProps.seedId),
    trackOnWillMount: ({ seedId }: Props) => !!seedId,
  },
)(LiveProfile);
