import RelatedPlaylistsDisplay from 'components/RelatedPlaylists';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getRecommendedPlaylistRecs } from 'state/Features/selectors';
import { useSelector } from 'react-redux';
import type { Playlist } from 'state/Playlist/types';

type Props = {
  relatedPlaylists: Array<Playlist>;
  stationName?: string;
  tilesInRow: number;
};

function RelatedPlaylists({
  relatedPlaylists,
  stationName = '',
  tilesInRow,
}: Props) {
  const translate = useTranslate();
  const recommendedPlaylistFlag = useSelector(getRecommendedPlaylistRecs);

  return recommendedPlaylistFlag && relatedPlaylists.length ?
      <RelatedPlaylistsDisplay
        aspectRatio={1}
        dataTest="related-playlists"
        itemSelectedLocation="1"
        name={translate(`Playlists from {stationName}`, { stationName })}
        playedFrom={1}
        relatedPlaylists={relatedPlaylists}
        showAllTiles={false}
        subtitleLines={2}
        tilesInRow={tilesInRow}
        titleLines={1}
      />
    : null;
}

export default RelatedPlaylists;
