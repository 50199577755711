import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Eyebrow from 'views/News/ContentArticle/primitives/Eyebrow';
import NavLink from 'components/NavLink';
import Section from 'components/Section';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import ShowMoreTiles from 'components/ShowMoreTiles';
import TileThumb from 'components/WideTile/primitives/TileThumb';
import TileTitle from 'components/WideTile/primitives/TileTitle';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ASPECT_RATIOS } from 'utils/mediaServerImageMaker/opsString/constants';
import { fit, resize } from 'utils/mediaServerImageMaker/opsString';
import { getContentLink } from 'state/Links/selectors';
import { getStationSite, getTimeline } from 'state/Live/selectors';
import { resolvePossiblyRelativeUrl } from 'utils/url';
import { useSelector } from 'react-redux';
import type { FunctionComponent } from 'react';
import type { LatestNewsData } from './types';

type Props = {
  clickedFrom: string;
};

const tilesInRow = 3;

const LatestNews: FunctionComponent<Props> = ({ clickedFrom }) => {
  const stationSite = useSelector(getStationSite);
  const articles = useSelector(getTimeline);
  const contentLink = useSelector(getContentLink);
  const translate = useTranslate();

  const header = (
    <SectionHeader hasMobileBottomLink>
      {translate('Latest News')}
    </SectionHeader>
  );

  const sponsorEyebrow = <Eyebrow>{translate('Sponsor Content')}</Eyebrow>;

  // IHRWEB-16940: Remove “partner content” label from Live Profile editorial content.
  // Until we can verify the correct behavior for the content label, we don’t want to display this.
  // [DEM 05/20/2021]
  // const partnerContentEyebrow = <Eyebrow>{translate('Partner Content')}</Eyebrow>;
  const partnerContentEyebrow = <Eyebrow />;

  if (!articles?.length) {
    return null;
  }

  return (
    <Section
      dataTest="the-feed-section"
      hasBorder={false}
      hasExtraPadding={false}
      hasMobileBottomLink={false}
      header={header}
      suppressFirstOfType
    >
      <ShowMoreTiles
        tilesData={articles.map(
          ({
            external_url: externalUrl,
            feed_vendor: feedVendor,
            img,
            is_sponsored: isSponsored,
            permalink,
            slug,
            title,
          }: LatestNewsData) => {
            let resolvedExternalUrl = externalUrl;
            resolvedExternalUrl &&= resolvePossiblyRelativeUrl(
              externalUrl,
              stationSite,
            )!;

            const href =
              permalink || resolvedExternalUrl || `${contentLink}${slug}/`;

            let eyebrow;
            if (isSponsored) eyebrow = sponsorEyebrow;
            if (feedVendor && externalUrl && !isSponsored)
              eyebrow = partnerContentEyebrow;

            return {
              children: (
                <NavLink clickedFrom={clickedFrom} target="_blank" to={href}>
                  <TileThumb>
                    <CatalogImage
                      alt={title}
                      aspectRatio={16 / 9}
                      ops={[fit(...ASPECT_RATIOS['16:9']), resize(0, 171)]}
                      src={img}
                    />
                  </TileThumb>
                </NavLink>
              ),
              title: (
                <TileTitle>
                  {eyebrow}
                  <NavLink clickedFrom={clickedFrom} target="_blank" to={href}>
                    <Truncate lines={4}>{title}</Truncate>
                  </NavLink>
                </TileTitle>
              ),
              key: slug || title,
              tilesInRow,
            };
          },
        )}
        tilesInRow={tilesInRow}
      />
    </Section>
  );
};

export default LatestNews;
