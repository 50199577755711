import styled from '@emotion/styled';
import { BREAKPOINTS } from 'constants/responsive';

const HeroContent = styled('div')({
  display: 'flex',
  margin: '0 auto',
  maxWidth: BREAKPOINTS.X4LARGENEW,
  padding: '3.6rem 0 3rem',
  position: 'relative',
});

export default HeroContent;
