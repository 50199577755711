import OnAirSchedule from './OnAirSchedule';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import {
  getOnAirName,
  getOnAirTarget,
  getOnAirThumbnail,
  getUpcoming,
} from 'state/Live/selectors';
import { State } from 'state/types';
import { StateProps } from './types';

export default connect(
  createStructuredSelector<State, StateProps>({
    coming: getUpcoming,
    mediaServerUrl: getMediaServerUrl,
    name: getOnAirName,
    siteUrl: getSiteUrl,
    target: getOnAirTarget,
    thumbnail: getOnAirThumbnail,
  }),
)(OnAirSchedule);
