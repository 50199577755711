import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import TileThumb from 'components/WideTile/primitives/TileThumb';

const WideTileContainer = styled('div')(({ theme }) => ({
  margin: '0 0.5rem',
  width: 'calc(33% - 1rem)',
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    ':nth-of-type(3), :nth-of-type(4)': {
      display: 'inline-block',
    },
    [TileThumb.toString()]: {
      float: 'left',
      marginRight: '1rem',
      width: '10rem',
    },
    marginTop: '1rem',
    width: 'calc(100% - 1rem)',
  },
}));

export default WideTileContainer;
