import styled from '@emotion/styled';

const RoundImageWrapper = styled('div')({
  borderRadius: '50%',
  clipPath: 'content-box', // keep the img overflow hidden in safari during transition
  float: 'left',
  height: '4.5rem',
  marginRight: '1rem',
  overflow: 'hidden',
  width: '4.5rem',
});

export default RoundImageWrapper;
