import CategoryTiles from '../PlaylistCategory/CategoryTiles';
import {
  PlaylistCategory as Category,
  CategoryTitle,
} from '../PlaylistCategory/primitives';
import {
  getAllAccessPreview,
  getIsTrialEligible,
  getSubscriptionType,
} from 'state/Entitlements/selectors';
import { useSelector } from 'react-redux';
import type { CategoryTile as CategoryTileType } from '../PlaylistCategory/CategoryTiles';
import type { Playlist } from 'state/Playlist/types';

type Props = {
  aspectRatio?: number;
  dataTest?: string;
  itemSelectedLocation: string;
  name: string;
  playedFrom: number;
  showAllTiles?: boolean;
  subtitleLines?: number;
  relatedPlaylists?: Array<Playlist>;
  tilesInRow: number;
  titleLines?: number;
};

const RelatedPlaylists = ({
  aspectRatio = 1,
  dataTest,
  itemSelectedLocation,
  name,
  playedFrom,
  showAllTiles = false,
  subtitleLines,
  relatedPlaylists = [],
  tilesInRow,
  titleLines,
}: Props) => {
  const allAccessPreview = useSelector(getAllAccessPreview);
  const isTrialEligible = useSelector(getIsTrialEligible);
  const subscriptionType = useSelector(getSubscriptionType);

  if (!relatedPlaylists.length) return null;

  const tileData: Array<CategoryTileType> = relatedPlaylists.map(playlist => {
    /**
     * TODO: This can be removed if we identify the root issue of why this data is missing sometimes.
     * IHRWEB-19787 - Caleb W
     */
    if (!playlist?.urls?.image) {
      // eslint-disable-next-line no-console
      console.error('playlist missing image:', playlist);
      window?.newrelic?.noticeError(
        new Error(
          `playlist ${playlist?.id} (${playlist?.name}) missing image (urls.image)`,
        ),
      );
    }

    const imageUrl = playlist?.urls?.image;

    return {
      cardId: playlist.id,
      id: {
        id: playlist.id,
        userId: String(playlist.ownerId),
      },
      imageUrl,
      isPlaylist: true,
      subCategoryLink: playlist.url,
      subtitle: playlist.description,
      title: playlist.name,
    };
  });

  return (
    <Category data-test={dataTest} showBorder={false}>
      <CategoryTitle oneInstance>{name}</CategoryTitle>
      <CategoryTiles
        allAccessPreview={allAccessPreview}
        aspectRatio={aspectRatio}
        isTrialEligible={isTrialEligible}
        itemSelectedLocation={itemSelectedLocation}
        playedFrom={playedFrom}
        showAllTiles={showAllTiles}
        subscriptionType={subscriptionType}
        subtitleLines={subtitleLines}
        tiles={tileData}
        tilesInRow={tilesInRow}
        titleLines={titleLines}
        useBareImage
      />
    </Category>
  );
};

export default RelatedPlaylists;
