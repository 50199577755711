import styled from '@emotion/styled';

const TileThumb = styled('div')(({ theme }) => ({
  borderRadius: '0.5rem',
  boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
  position: 'relative',

  img: {
    borderRadius: 0,
  },

  '.image:after, .image:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    transition: 'background 200ms',
  },

  '&:hover, &.cy-hover': {
    '.image::after': {
      background: theme.colors.transparent.dark,
    },
  },

  '.image img': {
    border: 'none',
    borderRadius: '0.5rem',
  },
}));

export default TileThumb;
