import ShareButton from 'components/Social/Share/ShareButton';
import ShareButtonWrapper from './primitives/ShareButtonWrapper';
import { ShareTypes } from 'components/Social/Share/constants';
import type { SocialProps } from 'components/Social/types';

function SocialShare({
  dimensions,
  seedId,
  episodeId,
  seedType,
  stationName,
  url,
  model,
  hideDescription,
  description,
  logo,
}: SocialProps) {
  return (
    <ShareButtonWrapper>
      <ShareButton
        description={description}
        dimensions={dimensions}
        episodeId={episodeId}
        genreLogo={logo}
        hideDescription={hideDescription}
        model={model}
        seedId={seedId}
        seedType={seedType}
        stationName={stationName}
        type={ShareTypes.IconOnly}
        url={url}
      />
    </ShareButtonWrapper>
  );
}

export default SocialShare;
