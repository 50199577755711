import styled from '@emotion/styled';

const Title = styled('div')(({ theme }) => ({
  float: 'left',
  fontSize: theme.fonts.size[13],
  fontWeight: theme.fonts.weight.medium,
  marginTop: '.5rem',
  width: '100%',
}));

export default Title;
