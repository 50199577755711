import MenuItem from 'components/Tooltip/primitives/MenuItem';
import styled from '@emotion/styled';

const ConnectDropdownMenuItem = styled(MenuItem)(({ theme }) => ({
  width: '25rem',
  '&:hover': {
    backgroundColor: theme.colors.gray['150'],
  },
}));

export default ConnectDropdownMenuItem;
