import styled from '@emotion/styled';

const HeroDescription = styled('h2')(({ theme }) => ({
  color: theme.colors.white.primary,
  fontSize: '1.6rem',
  fontWeight: 'normal',
  textAlign: 'left',
}));

export default HeroDescription;
